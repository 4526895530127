import image1 from '../../../assets/imagen1.jpg'
import image2 from '../../../assets/imagen2.jpg'
import image1png from '../../../assets/image1.png'
import image2png from '../../../assets/image2.png'
import image3png from '../../../assets/image3.png'
import image4png from '../../../assets/image4.png'
import image5png from '../../../assets/image5.png'
import image6png from '../../../assets/image6.png'
import image7png from '../../../assets/image7.png'
import image8png from '../../../assets/image8.png'
import image9png from '../../../assets/image9.png'
import image10png from '../../../assets/image10.png'



const breakpoints = [3840, 2400, 1080, 640, 384, 256, 128, 96, 64, 48];

const unsplashLink = (id: string, width: number, height: number) =>
    `https://source.unsplash.com/${id}/${width}x${height}`;

const unsplashPhotos = [
    { src: image1, width: 1080, height: 800 },
    { src: image2, width: 1080, height: 800 },
    { src: image1png, width: 1080, height: 800 },
    { src: image2png, width: 1080, height: 800 },
    { src: image3png, width: 1080, height: 800 },
    { src: image4png, width: 1080, height: 800 },
    { src: image5png, width: 1080, height: 800 },
    { src: image6png, width: 1080, height: 800 },
    { src: image7png, width: 1080, height: 800 },
    { src: image8png, width: 1080, height: 800 },
    { src: image9png, width: 1080, height: 800 },
    { src: image10png, width: 1080, height: 800 },
];

const photos = unsplashPhotos.map((photo) => {
    const width = breakpoints[0];
    const height = (photo.height / photo.width) * width;

    return {
        src: photo.src,
        width,
        height,
        srcSet: breakpoints.map((breakpoint) => {
            const height = Math.round((photo.height / photo.width) * breakpoint);
            return {
                src: photo.src,
                width: breakpoint,
                height,
            };
        }),
    };
});

export default photos;
