// import { blue, pink } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

export const appTheme = createTheme({
    palette: {
        primary: {
            main: '#C00D0E',
            contrastText: "#fff",
            light: "#C00D0E",
            dark: "rgba(192, 13, 14,0.7)"
        },
        secondary: {
            main: '#C00D0E',
            contrastText: "#f2f2f2",
            light: "#f2f2f2",
            dark: "#231f20"
        },
        info: {
            main: '#C00D0E',
            contrastText: "#231f20",
            light: "#f2f2f2",
            dark: "#f2f2f2"
        }
    },
});