import { Box, Grid } from '@mui/material'
import React from 'react'
import { FeatureCard } from './FeatureCard'
import image10 from "../../../assets/image10.png"
import image1 from "../../../assets/image1.png"
import image5 from "../../../assets/image5.png"

export const FeaturesContainer = () => {
    const features = [
        {
            title: "Experiencia y conocimiento especializado",
            text: 'Contantamos con un equipo altamente capacitado y especializado en el campo de la seguridad, automatización y fibra óptica. Con años de experiencia en la industria, tienes el conocimiento necesario para ofrecer soluciones tecnológicamente avanzadas y adaptadas a las necesidades específicas de tus clientes.',
            image: image10
        },
        {
            title: "Enfoque personalizado y atención al cliente",
            text: 'Comprendemos a fondo las necesidades individuales de cada cliente. Te tomas el tiempo para escuchar y entender sus requerimientos, y desarrollas soluciones personalizadas que se adaptan a sus objetivos y presupuestos. Además, brindas un servicio al cliente excepcional, estableciendo relaciones de confianza a largo plazo con tus clientes.',
            image: image1
        },
        {
            title: "Calidad y confiabilidad",
            text: 'Ofrecemos productos y servicios de la más alta calidad. Trabajas con proveedores de renombre y utilizas tecnología de vanguardia para garantizar que tus soluciones de seguridad, automatización y fibra óptica sean confiables, eficientes y duraderas. La calidad y la satisfacción del cliente son aspectos fundamentales en todo lo que haces.',
            image: image5
        },
    ]
    return (
        <Box>
            <Grid container spacing={12}>
                {
                    features.map((feature, zIndex) => <FeatureCard key={zIndex} id={zIndex} image={feature.image} text={feature.text} title={feature.title} />
                    )
                }
            </Grid>
        </Box>
    )
}
