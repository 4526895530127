import { Box, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import image3 from "../../assets/image1.png"

export const WhatWeDoCard = () => {
    return (
        <Paper
            elevation={6}
            sx={{
                width: "100%",
                minHeight: 550,
                display: 'flex',
                flexDirection: "column",
                alignItems: 'center',
                p: 3,
                gap: 3
            }}
        >
            <Typography variant='h3' fontWeight={'bold'}>
                ¿Que hacemos?
            </Typography>
            <Grid container height={"100%"} spacing={2}>
                <Grid item md={6}
                    sx={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        textAlign: 'justify'
                    }}
                >
                    <Typography>
                        Nuestra empresa es líder en el suministro de soluciones integrales en seguridad, automatización y conectividad basadas en tecnología de vanguardia. Proporcionamos servicios de instalación y configuración de sistemas de cámaras de seguridad para proteger hogares, negocios y espacios públicos, brindando tranquilidad y control total a nuestros clientes. Además, ofrecemos soluciones de automatización para optimizar procesos, mejorar la eficiencia y facilitar el monitoreo remoto de dispositivos y sistemas. Nos especializamos en el diseño y despliegue de redes de fibra óptica, asegurando una conectividad confiable y de alta velocidad para empresas, comunidades residenciales y áreas geográficas extensas. Nuestro enfoque personalizado, atención al detalle y experiencia en la industria nos distinguen como la elección ideal para satisfacer las necesidades de seguridad, automatización y conectividad de nuestros clientes.
                        <br />
                        <br />
                        En nuestra empresa, nos enorgullece ofrecer un conjunto completo de servicios que abarcan desde la planificación y consultoría inicial hasta la implementación y el soporte continuo. Nuestro equipo altamente capacitado y especializado trabaja en estrecha colaboración con cada cliente para entender sus requisitos específicos y brindar soluciones adaptadas a sus necesidades. Ya sea que necesites proteger tu hogar, automatizar tu oficina o establecimiento comercial, o establecer una infraestructura de fibra óptica para una conectividad confiable, estamos aquí para ayudarte. Nos esforzamos por superar las expectativas de nuestros clientes al brindarles productos y servicios de la más alta calidad, respaldados por un servicio al cliente excepcional. Si estás buscando soluciones confiables y tecnológicamente avanzadas en seguridad, automatización y fibra óptica, estaremos encantados de ser tu socio de confianza en esta aventura.
                    </Typography>
                </Grid>
                <Grid item md={6} p={{ xs: 0, md: 2, lg: 5 }} sx={{display:"flex", alignItems:"center"}}>
                    <Box width={"100%"}>
                        <img src={image3} alt="¿Que hacemos?" width={"100%"} />
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    )
}
