import React from 'react'
// core version + navigation, pagination modules:
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "./Carousel.css";
import "swiper/css/navigation";
import 'swiper/css/pagination';
import { Navigation, Pagination } from "swiper";
import { Box } from '@mui/material';
import { Item } from './Item';
import image4 from "../../../assets/image4.png"
import image7 from "../../../assets/image7.png"
import image8 from "../../../assets/image8.png"
import image6 from "../../../assets/image6.png"
import image2 from "../../../assets/image2.png"
import image5 from "../../../assets/image5.png"


export const Carousel = () => {
    const items = [
        {
            img: image6,
            name: "Instalación y configuración de sistemas de cámaras de seguridad",
            description: "Diseño e implementación de sistemas de videovigilancia para proteger propiedades y garantizar la seguridad de los clientes."
        },
        {
            img: image4,
            name: "Integración de sistemas de automatización",
            description: "Desarrollo e instalación de soluciones de automatización para controlar y monitorear dispositivos y sistemas en hogares, oficinas y establecimientos comerciales."
        }
        ,
        {
            img: image8,
            name: "Diseño de redes de fibra óptica",
            description: "Planificación e implementación de infraestructuras de fibra óptica para garantizar una conectividad confiable y de alta velocidad en edificios y áreas geográficas extensas."
        }
        ,
        {
            img: image7,
            name: "Mantenimiento y soporte técnico",
            description: "Servicios de mantenimiento preventivo y correctivo, así como soporte técnico para garantizar el funcionamiento continuo de los sistemas de seguridad, automatización y fibra óptica."
        },
        {
            img: image2,
            name: "Integración de sistemas de seguridad",
            description: "Integración de sistemas de cámaras de seguridad con otros sistemas de seguridad, como control de acceso, alarmas y sistemas de detección de incendios."
        },
        {
            img: image5,
            name: "Consultoría y diseño personalizado",
            description: "Asesoramiento experto para identificar las necesidades específicas de seguridad, automatización y conectividad de los clientes, y diseño de soluciones personalizadas que se adapten a sus requerimientos."
        }
    ]
    return (
        <Box sx={{ position: 'relative', height: 500 }}>
            <Swiper
                navigation={true}
                modules={[Navigation, Pagination]}
                pagination={{ clickable: true }}
            >
                {
                    items.map((item, zIndex) =>
                        <SwiperSlide key={zIndex}>
                            <Item item={item} />
                        </SwiperSlide>
                    )
                }
            </Swiper>
        </Box>
    )
}
