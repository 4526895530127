import { Box, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import RouteIcon from '@mui/icons-material/Route';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import DiamondIcon from '@mui/icons-material/Diamond';
export const CardsContainer = () => {
    return (
        <Grid container spacing={6}>

            <Grid item lg={4} minHeight={300}
            >
                <Box
                    sx={{
                        position: "relative"
                    }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            borderRadius: "50%",
                            backgroundColor: "#fff",
                            height: 40,
                            width: 40,
                            boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
                            transform: "translate(-50%,-50%)",
                            left: "50%",
                            display: "flex",
                            margin: "0 auto",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        <TravelExploreIcon color='info'></TravelExploreIcon>
                    </Box>
                    <Paper sx={{ height: "100%" }} elevation={6}>
                        <Typography variant='h6' color={"#C00D0E"} py={4} textAlign={"center"}>Visión</Typography>
                        <Typography variant='body1' color={"#000"} pb={4} px={4} textAlign={"justify"}>Proporcionar soluciones de seguridad, automatización y conectividad confiables y de vanguardia, utilizando tecnología de fibra óptica, para garantizar la protección y el bienestar de nuestros clientes y sus activos.</Typography>
                    </Paper>
                </Box>

            </Grid>
            <Grid item lg={4} minHeight={300}

            >
                <Box
                    sx={{
                        position: "relative"
                    }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            borderRadius: "50%",
                            backgroundColor: "#fff",
                            height: 40,
                            width: 40,
                            boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
                            transform: "translate(-50%,-50%)",
                            left: "50%",
                            display: "flex",
                            margin: "0 auto",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        <RouteIcon color='info'></RouteIcon>
                    </Box>
                    <Paper sx={{ height: "100%" }} elevation={6}>
                        <Typography variant='h6' color={"#C00D0E"} py={4} textAlign={"center"}>Misión</Typography>
                        <Typography variant='body1' color={"#000"} pb={4} px={4} textAlign={"justify"}>Ser líderes en el mercado de seguridad y automatización, reconocidos por nuestra excelencia en tecnología, innovación y servicio al cliente, y ser una empresa de referencia en la implementación de soluciones basadas en fibra óptica.</Typography>
                    </Paper>
                </Box>

            </Grid>
            <Grid item lg={4} minHeight={300}

            >
                <Box
                    sx={{
                        position: "relative"
                    }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            borderRadius: "50%",
                            backgroundColor: "#fff",
                            height: 40,
                            width: 40,
                            boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
                            transform: "translate(-50%,-50%)",
                            left: "50%",
                            display: "flex",
                            margin: "0 auto",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        <DiamondIcon color='info'></DiamondIcon>
                    </Box>
                    <Paper sx={{ height: "100%" }} elevation={6}>
                        <Typography variant='h6' color={"#C00D0E"} py={4} textAlign={"center"}>Valores</Typography>
                        <Typography variant='body1' color={"#000"} pb={4} px={4} textAlign={"justify"}>Nos guiamos por la calidad, la innovación y la confianza. Aspiramos a brindar soluciones confiables y de calidad en seguridad, automatización y conectividad. Valoramos la responsabilidad, la colaboración y la sostenibilidad en todo lo que hacemos.</Typography>
                    </Paper>
                </Box>

            </Grid>

        </Grid>
    )
}
