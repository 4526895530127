import { Box, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import image2 from "../../assets/imagen2.jpg"

export const ProcessCard = () => {
    return (
        <Paper
            elevation={6}
            sx={{
                width: "100%",
                minHeight: 550,
                display: 'flex',
                flexDirection: "column",
                alignItems: 'center',
                p: 3,
                gap: 3
            }}
        >
            <Typography variant='h3' fontWeight={'bold'}>
                Nuestro Proceso
            </Typography>
            <Grid container height={"100%"} spacing={2}>
                <Grid item md={6} p={{ xs: 0, md: 2, lg: 5 }}>
                    <Box width={"100%"}>
                        <img src={image2} alt="¿Que hacemos?" width={"100%"} />
                    </Box>
                </Grid>
                <Grid item md={6}
                    sx={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        textAlign: 'justify'
                    }}
                >
                    <Typography>
                        Nuestros procesos empresariales están diseñados para garantizar la eficiencia y la calidad en todas las etapas de nuestro trabajo. Comenzamos por realizar un análisis exhaustivo de las necesidades y requisitos de cada cliente, lo cual nos permite entender a fondo sus objetivos y expectativas. A partir de ahí, nuestro equipo especializado desarrolla soluciones personalizadas, utilizando la mejor tecnología disponible en el mercado. Llevamos a cabo la instalación y configuración de sistemas de cámaras de seguridad, automatización y redes de fibra óptica con precisión y cuidado, asegurándonos de cumplir con los más altos estándares de calidad. Una vez que los sistemas están en funcionamiento, brindamos soporte técnico continuo, así como servicios de mantenimiento preventivo y correctivo para garantizar el rendimiento óptimo y la seguridad a largo plazo. Nuestro objetivo es brindar a nuestros clientes una experiencia fluida y sin complicaciones, desde la fase de consultoría inicial hasta la implementación y el soporte posterior.
                        <br />
                        <br />
                        En nuestra empresa, nos esforzamos por seguir procesos bien definidos y estandarizados para asegurar la consistencia y la calidad en todas nuestras operaciones. Nuestro enfoque comienza por una comunicación clara y abierta con nuestros clientes, estableciendo una relación de colaboración para entender sus necesidades. A partir de ahí, realizamos una evaluación exhaustiva y un diseño detallado de las soluciones que se ajusten a sus requisitos específicos. Durante la implementación, nos aseguramos de seguir protocolos y estándares de seguridad rigurosos, y utilizamos métodos probados para garantizar una instalación precisa y confiable. Además, contamos con procesos de monitoreo y control de calidad para asegurarnos de que nuestros sistemas estén funcionando de manera óptima y cumplan con las expectativas de nuestros clientes. Nos esforzamos constantemente por mejorar nuestros procesos y adaptarnos a las últimas innovaciones tecnológicas para mantenernos a la vanguardia de la industria.
                    </Typography>
                </Grid>

            </Grid>
        </Paper>
    )
}
