import { Box } from '@mui/material'
import React from 'react'
import { Hero } from '../components/AboutUs/Hero'
import { CardsContainer } from '../components/AboutUs/FeaturesCards/CardsContainer'
import { OurServicesContainer } from '../components/AboutUs/OurServices'
import { CustomersCarousel } from '../components/AboutUs/Customers/CustomersCarousel'
import { SuccessStories } from '../components/AboutUs/SuccessStories/SuccessStories'
import { GalleryCustom } from '../components/AboutUs/Gallery/GalleryCustom'

export const AboutUs = () => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 10
            }}
        >
            <Hero />
            <Box
                sx={{ px: { xs: 2, sm: 3, md: 5, lg: 10, xl: 20 }, gap: 10, display: "flex", flexDirection: "column" }}
            >
                <CardsContainer />
                {/* <SuccessStories /> */}
                <GalleryCustom />
                <OurServicesContainer />
                {/* <CustomersCarousel /> */}
            </Box>
        </Box>
    )
}
