import React, { ChangeEvent, FormEvent, useState } from 'react'
import { Box, Button, Grid, TextField } from '@mui/material'
import background from "../../assets/BGCircuit.png"
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import axios from 'axios';
import Swal from 'sweetalert2';
export const ContactForm = () => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
        name: '',
        email: '',
        summary: '',
        message: ''
    })
    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setLoading(true)
        axios.post(`${process.env.REACT_APP_NEXUS_BACK_URL as string}/api/v1/Email`,
            {
                apiKey: process.env.REACT_APP_API_KEY,
                email: data.email,
                subject: data.summary,
                body: data.message,
                name: data.name
            }
        )
            .then((result) => {
                setData({
                    name: '',
                    email: '',
                    summary: '',
                    message: ''
                })
                setLoading(false)
                Swal.fire({
                    title: "Correo enviado con exito",
                    icon: "success"
                })

            }).catch((err) => {
                setLoading(false)
                Swal.fire({
                    title: "Algo salio mal, intentelo nuevamente o revise la informacion ingresada",
                    icon: "error"
                })
            });
    }
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value });
    };
    return (
        <Grid
            item
            xs={12}
            md={6}
            sx={{
                position: 'relative',
                height: 600,
            }}
        >
            <Box
                sx={{
                    // backgroundImage: `linear-gradient(to right, #c00d0e, #e35170, #e991bd, #e9cbec, #ffffff)`,
                    height: 600,
                    backgroundColor: "#334a73",
                    width: '100%',
                    position: 'absolute',
                    zIndex: '0',
                    // filter: "blur(8px)"
                }}
            >
            </Box>
            <Box
                sx={{
                    height: 600,
                    width: '100%',
                    position: 'absolute',
                    zIndex: '1',
                    backgroundColor: "rgba(0,0,0,0.2)"
                }}
            >
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    gap: 2,
                    position: 'absolute',
                    zIndex: '1000',
                    width: "100%"
                }}
            >
                <Box onSubmit={handleSubmit} component={"form"} p={4} sx={{ "label": { color: "#fff !important" }, "input, textarea": { color: "#fff !important" }, display: "flex", flexDirection: "column", gap: 4, width: "100%", alignItems: "center" }}>
                    <TextField color="secondary" required label="Nombre" sx={{ width: { md: "50%" } }} name='name' onChange={handleChange} />
                    <TextField color="secondary" required label="Email" sx={{ width: { md: "50%" } }} name='email' onChange={handleChange} />
                    <TextField color="secondary" required label="Motivo" sx={{ width: { md: "50%" } }} name='summary' onChange={handleChange} />
                    <TextField color="secondary" required label="Mensaje" multiline rows={5} fullWidth name='message' onChange={handleChange} />
                    <Box
                        sx={{
                            display: "flex",
                            gap: 4
                        }}
                    >
                        <Button variant='contained' sx={{ p: 2 }} type='submit' disabled={loading}>
                            Enviar correo
                        </Button>
                        <Button variant='contained' onClick={() => window.open("https://api.whatsapp.com/send/?phone=56972159282&text&type=phone_number&app_absent=0", '_blank')}>
                            <WhatsAppIcon sx={{ mr: 1 }} /> Contactar por WhatsApp
                        </Button>
                    </Box>
                </Box>

            </Box>
        </Grid >
    )
}
