import React from 'react'

import { ContactUsContainer } from '../components/Contact Us';
import { Box, ThemeProvider, Typography } from '@mui/material';
import { appTheme } from '../styles/MUI/Theme';

export const ContactUs = () => {
    return (
        <ThemeProvider theme={appTheme}>
            <Box
                sx={{
                    height: { xs: "100%", md: "calc(100vh - 80px)" },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column"
                }}
            >
                <Typography variant='h2' fontWeight={"500"}>
                    Contactanos
                </Typography>
                <ContactUsContainer />
            </Box>
        </ThemeProvider>
    )
}
