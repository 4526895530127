import React from 'react'
import { Box, Grid, Paper, Typography } from '@mui/material'
import image2 from "../../../assets/imagen2.jpg"
import image6 from "../../../assets/image6.png"
import image1 from "../../../assets/image1.png"
import image10 from "../../../assets/image10.png"
import image7 from "../../../assets/image7.png"
import image5 from "../../../assets/image5.png"


export const OurServicesContainer = () => {
    return (
        <Box>
            <Typography variant='h3' fontWeight={"500"} color={"#C00D0E"} textAlign={"center"}>
                Nuestros servicios
            </Typography>
            <Grid container minHeight={500} spacing={4} sx={{ justifyContent: "space-evenly", my: 4 }}>

                <Grid item lg={3} display={"flex"}>
                    <Paper sx={{ height: "100%", p: 2, display: 'flex', flexDirection: "column", gap: 2 }} elevation={4}>
                        <Box
                            sx={{
                                width: "100%",
                                borderRadius: "4px"
                            }}
                        >
                            <img src={image6} alt="Service" width={"100%"} style={{ borderRadius: "4px" }} />
                        </Box>
                        <Typography variant='h5' fontWeight={"500"} textAlign={"center"}>
                            Instalación y configuración de sistemas de cámaras de seguridad
                        </Typography>
                        <Typography variant='body1' textAlign={"justify"}>
                            Diseño e implementación de sistemas de videovigilancia para proteger propiedades y garantizar la seguridad de los clientes.
                        </Typography>
                    </Paper>
                </Grid>

                <Grid item lg={3} display={"flex"} >
                    <Paper sx={{ height: "100%", p: 2, display: 'flex', flexDirection: "column", gap: 2, flexGrow: 1 }} elevation={4}>
                        <Box
                            sx={{
                                width: "100%",
                                borderRadius: "4px"
                            }}
                        >
                            <img src={image1} alt="Service" width={"100%"} style={{ borderRadius: "4px" }} />
                        </Box>
                        <Typography variant='h5' fontWeight={"500"} textAlign={"center"}>
                            Integración de sistemas de automatización
                        </Typography>
                        <Typography variant='body1' textAlign={"justify"}>
                            Desarrollo e instalación de soluciones de automatización para controlar y monitorear dispositivos y sistemas en hogares, oficinas y establecimientos comerciales.
                        </Typography>
                    </Paper>
                </Grid>

                <Grid item lg={3} display={"flex"}>
                    <Paper sx={{ height: "100%", p: 2, display: 'flex', flexDirection: "column", gap: 2 }} elevation={4}>
                        <Box
                            sx={{
                                width: "100%",
                                borderRadius: "4px"
                            }}
                        >
                            <img src={image10} alt="Service" width={"100%"} style={{ borderRadius: "4px" }} />
                        </Box>
                        <Typography variant='h5' fontWeight={"500"} textAlign={"center"}>
                            Diseño de redes de fibra óptica
                        </Typography>
                        <Typography variant='body1' textAlign={"justify"}>
                            Planificación e implementación de infraestructuras de fibra óptica para garantizar una conectividad confiable y de alta velocidad en edificios y áreas geográficas extensas.
                        </Typography>
                    </Paper>
                </Grid>

                <Grid item lg={3} display={"flex"}>
                    <Paper sx={{ height: "100%", p: 2, display: 'flex', flexDirection: "column", gap: 2 }} elevation={4}>
                        <Box
                            sx={{
                                width: "100%",
                                borderRadius: "4px"
                            }}
                        >
                            <img src={image7} alt="Service" width={"100%"} style={{ borderRadius: "4px" }} />
                        </Box>
                        <Typography variant='h5' fontWeight={"500"} textAlign={"center"}>
                            Mantenimiento y soporte técnico
                        </Typography>
                        <Typography variant='body1' textAlign={"justify"}>
                            Servicios de mantenimiento preventivo y correctivo, así como soporte técnico para garantizar el funcionamiento continuo de los sistemas de seguridad, automatización y fibra óptica.
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item lg={3} display={"flex"}>
                    <Paper sx={{ height: "100%", p: 2, display: 'flex', flexDirection: "column", gap: 2 }} elevation={4}>
                        <Box
                            sx={{
                                width: "100%",
                                borderRadius: "4px"
                            }}
                        >
                            <img src={image2} alt="Service" width={"100%"} style={{ borderRadius: "4px" }} />
                        </Box>
                        <Typography variant='h5' fontWeight={"500"} textAlign={"center"}>
                            Integración de sistemas de seguridad
                        </Typography>
                        <Typography variant='body1' textAlign={"justify"}>
                            Integración de sistemas de cámaras de seguridad con otros sistemas de seguridad, como control de acceso, alarmas y sistemas de detección de incendios.
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item lg={3} display={"flex"}>
                    <Paper sx={{ height: "100%", p: 2, display: 'flex', flexDirection: "column", gap: 2 }} elevation={4}>
                        <Box
                            sx={{
                                width: "100%",
                                borderRadius: "4px"
                            }}
                        >
                            <img src={image5} alt="Service" width={"100%"} style={{ borderRadius: "4px" }} />
                        </Box>
                        <Typography variant='h5' fontWeight={"500"} textAlign={"center"}>
                            Consultoría y diseño personalizado
                        </Typography>
                        <Typography variant='body1' textAlign={"justify"}>
                            Asesoramiento experto para identificar las necesidades específicas de seguridad, automatización y conectividad de los clientes, y diseño de soluciones personalizadas que se adapten a sus requerimientos.
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}
