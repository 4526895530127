import { Grid } from '@mui/material'
import React from 'react'
import { Map } from './Map'
import { ContactForm } from './ContactForm'


export const ContactUsContainer = () => {
    return (
        <Grid container sx={{ height: { xs: "100%", md: 600 }, px: { xs: 2, sm: 3, md: 5, lg: 10, xl: 20 } }}>
            <Map />
            <ContactForm />
        </Grid>
    )
}
