import { Box } from '@mui/material'
import React from 'react'
import { Hero } from '../components/Home/Hero'
import { Carousel } from '../components/Home/Swiper'
import { WhatWeDoCard } from '../components/Home/WhatWeDoCard'
import { FeaturesContainer } from '../components/Home/Features/FeaturesContainer'
import { ProcessCard } from '../components/Home/ProcessCard'

export const Home = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: "column",
                gap: 10
            }}
        >
            <Hero />
            <Box
                px={{ xs: 2, sm: 3, md: 5, lg: 10, xl: 20 }}
                sx={{
                    display: 'flex',
                    flexDirection: "column",
                    gap: 10
                }}
            >
                <Carousel />
                <WhatWeDoCard />
                <FeaturesContainer />
                <ProcessCard />
            </Box>
        </Box>
    )
}
