import { Box, Typography } from '@mui/material'
import React from 'react'
import logo from "../../assets/BercompLogo.png"
import { Link } from 'react-router-dom'
export const Footer = () => {
    return (
        <Box
            sx={{
                backgroundColor: "#EAEAEA",
                height: 250,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: 2
            }}
        >
            <Box
                sx={{
                    width: 250
                }}
            >
                <img src={logo} alt="Bercomp Logo" width={"100%"} />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',

                }}
            >
                <Box>
                    <Typography
                        variant='caption'
                    >
                        Limache 3421, Reitz II, oficina 816, Viña del Mar.
                    </Typography>

                </Box>
                <Box>
                    <Typography
                        variant='caption'
                    >

                        +56 9 7215 9282
                    </Typography>

                </Box>
            </Box>

            <Box
                sx={{
                    "a": {
                        color: "#C00D0E",
                        textDecoration: "none",
                        fontWeight: "400",
                        fontSize: "1.3   rem"
                    },
                    display: "flex",
                    gap: 3
                }}
            >
                <Link to={"/"}>
                    Home
                </Link>
                <Link to={"/contact"}>
                    Contactanos
                </Link>
                <Link to={"/about"}>
                    Sobre nosotros
                </Link>
            </Box>
            <Box px={2}>
                <Typography variant="h2" component="h3" sx={{ fontSize: "0.875rem", textAlign: "center" }} gutterBottom >Powered By <a style={{ color: "inherit" }} href="https://tesseractsoftwares.com/en-us">Tesseract</a> ®
                </Typography>
            </Box>

        </Box>
    )
}
