import { Box, Typography } from "@mui/material"
import { Link, useLocation } from "react-router-dom"
import { DropDownItem } from "./DropdownItem"
// import "../styles/CSS/Home.css"


export const NavRoutes = () => {
    const actualRoute = useLocation()
    const routes = [
        {
            path: `/contact`,
            title: "Contactanos"
        },
        {
            path: `/about`,
            title: "Sobre nosotros"
        },
    ]
    return (
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {
                routes.map((route, index) => {
                    return (
                        <Link key={index} to={route.path} className={`navItem ${actualRoute.pathname === route.path ? "active" : ""} `}>
                            <Typography variant="h6" component="div" sx={{ fontSize: "1rem !important" }} >
                                {(route.title).toUpperCase()}
                            </Typography>
                        </Link>

                    )
                })
            }
            <DropDownItem />
        </Box>
    )
}
