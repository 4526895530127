import { Box, Typography } from '@mui/material'
import React from 'react'

interface Props {
    item: { img: string, name: string, description: string }
}
export const Item = ({ item }: Props) => {
    return (
        <Box
            sx={{
                height: '100%',
                width: "100%",
                position: "relative"
            }}
        >

            <Box
                sx={{
                    clipPath: { xs: "polygon(0 50%, 0% 100%, 100% 100%, 100% 50%)", sm: "polygon(0 60%, 0% 100%, 100% 100%, 100% 60%)", md: "polygon(100% 100%,0% 40%,0% 100%)", lg: "polygon(80% 100%,0% 50%,0% 100%)" },
                    backgroundColor: "rgba( 192, 13, 14 ,0.8)",
                    height: '100%',
                    width: "100%",
                    position: "absolute",
                    left: 0,
                    top: 0
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        bottom: 0,
                        left: "0",
                        textAlign: "start",
                        width: { md: "50%" },
                        p: 1,
                        color: "#fff"
                        // transform:"translate(-50%,-90%)"

                    }}
                >
                    <Typography
                        variant='h5'
                        fontWeight={"bold"}
                    >
                        {item.name}
                    </Typography>
                    <Typography
                        variant='body1'
                    >
                        {item.description}
                    </Typography>
                </Box>
            </Box>

            <Box
                sx={{
                    height: '100%',
                    width: "100%"
                }}
            >
                <img src={item.img} alt="" style={{ height: "100%" }} />
            </Box>
        </Box>
    )
}
