import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { Home } from '../pages/Home'
import TNavBar from '../components/NavBar/NavBar'
import { ContactUs } from '../pages/ContactUs'
import { AboutUs } from '../pages/AboutUs'
import { Footer } from '../components/Footer'

export const AppRouter = () => {
    const location = useLocation()
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    return (
        <Box
            sx={{
                backgroundColor: "#fff",
                minHeight: "100vh",
                color: '#000'
            }}
        >
            <TNavBar>
                <Routes>
                    <Route path={`/`} element={<Home></Home>} />
                    <Route path={`/contact`} element={<ContactUs></ContactUs>} />
                    <Route path={`/about`} element={<AboutUs></AboutUs>} />

                    <Route path={`/*`} element={<Navigate to={"/"}></Navigate>} />
                </Routes >
            </TNavBar>
            <Footer />
        </Box>
    )
}
