import { Box, Button, Paper, Typography } from '@mui/material'
import React from 'react'
import background from "../../assets/imagen1.jpg"
import { useNavigate } from 'react-router-dom'

export const Hero = () => {
    const navigate = useNavigate()
    return (
        <Box
            sx={{
                position: 'relative',
                height: 550,
            }}
        >
            <Box
                sx={{
                    backgroundImage: `url("${background}")`,
                    height: 550,
                    width: '100%',
                    position: 'absolute',
                    zIndex: '0',
                    filter: "blur(4px)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover"
                }}
            >
            </Box>
            <Box
                sx={{
                    height: 550,
                    width: '100%',
                    position: 'absolute',
                    zIndex: '1',
                    backgroundColor: "rgba(0,0,0,0.5)"
                }}
            >
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    gap: 2,
                    position: 'absolute',
                    zIndex: '1000',
                    maxWidth: 400,
                    left: "50%",
                    // top: "50%",
                    transform: "translate(-50%,50%)"
                }}
            >
                <Typography variant='h2' fontWeight={"bold"} sx={{ color: "#fff" }}>
                    ¿Que es <span style={{ color: "#C00D0E" }}>BERCOMP</span>?
                </Typography>

                <Typography
                    sx={{ color: "#fff", textAlign: "justify" }}
                >
                    Líderes en soluciones de seguridad, automatización y fibra óptica. Ofrecemos tranquilidad, eficiencia y conectividad confiable a través de soluciones personalizadas y tecnológicamente avanzadas. Comprometidos con la excelencia y la innovación.
                </Typography>
            </Box>
        </Box >
    )
}
