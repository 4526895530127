import { Box, Button, Paper, Typography } from '@mui/material'
import React from 'react'
import background from "../../assets/BGCircuit.png"
import { useNavigate } from 'react-router-dom'

export const Hero = () => {
    const navigate = useNavigate()
    return (
        <Box
            sx={{
                position: 'relative',
                height: 550,
            }}
        >
            <Box
                sx={{
                    backgroundImage: `url("${background}")`,
                    height: 550,
                    width: '100%',
                    position: 'absolute',
                    zIndex: '0',
                    filter: "blur(6px)"
                }}
            >
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    gap: 2,
                    position: 'absolute',
                    zIndex: '1000',
                    maxWidth: 400,
                    left: "50%",
                    top: { xs: "-30%", md: "0%" },
                    transform: "translate(-50%,50%)"
                }}
            >
                <Typography variant='h2' fontWeight={"bold"} color={"#5b5959"}>
                    Bercomp
                </Typography>

                <Typography
                >
                    ¡Protege tu hogar o negocio, optimiza tus procesos y conecta de manera confiable con nuestra empresa líder en soluciones de seguridad, automatización y fibra óptica! Contáctanos hoy mismo para descubrir cómo podemos ayudarte a satisfacer tus necesidades y brindarte la tranquilidad que mereces. ¡No esperes más, juntos crearemos un entorno más seguro y eficiente!
                </Typography>
                <Button variant='contained' color='primary' onClick={() => navigate("/contact")}>
                    Contactanos
                </Button>
            </Box>
        </Box >
    )
}
