import { Api, Code, ConnectWithoutContactTwoTone, Home, Language, Money, NetworkCellRounded, Newspaper, Paid, People, Phone, PhoneAndroid, TableBar, TableChart, Web, Work, } from "@mui/icons-material";
import { Link as MaterialLink, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom"
// import GroupAddIcon from '@mui/icons-material/GroupAdd';
import React from "react";
import TListItem from "./TListItem";
// import { useAppSelector } from "../../../hooks/redux-hooks";
// import { LineChartOutlined } from "@ant-design/icons";
import ContactPageRoundedIcon from '@mui/icons-material/ContactPageRounded';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import InfoIcon from '@mui/icons-material/Info';
import DrawerDropItem from "./DrawerDropItem";
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import HubIcon from '@mui/icons-material/Hub';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
export default function TDrawerItemsList(props: any) {
    const actualRoute = useLocation()


    const routes = [

        {
            path: `/contact`,
            title: "Contactanos",
            icon: PermContactCalendarIcon
        },
        {
            path: `/about`,
            title: "Sobre nosotros",
            icon: PersonIcon
        },
    ]
    // const itemsDropsDown = [
    //     {
    //         link: `/${i18n.language}/about`,
    //         icon: <PersonIcon />,
    //         title: t("Navbar.About")
    //     },
    //     {
    //         title: t("Navbar.SuccesStories"),
    //         link: `/${i18n.language}/successStories`,
    //         icon: <DoneAllIcon />,

    //     },
    // ]

    return (
        <React.Fragment>
            {/* 
            <ListItem component={"a"} sx={{ color: "inherit" }} href={`https://gamesground.tesseractsoftwares.com/${i18n.language}`} className={`drawerItem`}>
                <ListItemIcon>
                    <VideogameAssetIcon />
                </ListItemIcon>
                <ListItemText primary={"GamesGround"} className="fontDrawerItem" />
            </ListItem> */}
            {
                routes.map((route, index) => {
                    return (
                        <TListItem key={index} title={route.title} link={route.path} icon={<route.icon />} />

                    )
                })
            }
            {/* <DrawerDropItem icon={<GroupsIcon />} title={t("Navbar.title")} items={itemsDropsDown} /> */}

        </React.Fragment>
    );
}